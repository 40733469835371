import React, { FC, useEffect, useState } from "react";
import {
  GatsbyImage,
  getImage,
  IGatsbyImageData,
  StaticImage,
} from "gatsby-plugin-image";
import Layout from "../components/core/Layout";
import { removeItemFromArray } from "../utils/ArrayUtils";
import { navigate } from "gatsby";
import Cancel from "@material-ui/icons/CancelOutlined";
import PageTitle from "../components/core/PageTitle";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import DonateRow from "../components/articles/DonateRow";
import CodeBlock from "./CodeBlock";

interface StringKeyArray {
  [key: string]: any[];
}

interface StringKey {
  [key: string]: string;
}

interface DeclarativeMapperPageProps {
  pageContext: {
    frameworks: {
      concepts: any[];
      metadata: any[];
      JetpackCompose: any[];
      Flutter: any[];
      React: any[];
      SwiftUI: any[];
      VueJS: any[];
    };
    frameworksMdx: any[];
    comparison: string[];
  };
}

const gridCols: StringKey = {
  "0": "grid-cols-10",
  "1": "grid-cols-10",
  "2": "grid-cols-10",
  "3": "grid-cols-11",
  "4": "grid-cols-9",
};

const keyColSpan: StringKey = {
  "0": "xs:col-span-10 lg:col-span-3",
  "1": "xs:col-span-10 lg:col-span-3",
  "2": "xs:col-span-10 lg:col-span-2",
  "3": "xs:col-span-11 lg:col-span-2",
  "4": "xs:col-span-9 lg:col-span-1",
};

const itemColSpan: StringKey = {
  "0": "xs:col-span-10 lg:col-span-7",
  "1": "xs:col-span-10 lg:col-span-7",
  "2": "xs:col-span-10 lg:col-span-4",
  "3": "xs:col-span-11 lg:col-span-3",
  "4": "xs:col-span-9 lg:col-span-2",
};

function frameworkToDisplayName(framework: string): string {
  if (framework == "JetpackCompose") {
    return "Jetpack Compose";
  } else if (framework == "VueJS") {
    return "Vue.js";
  } else {
    return framework;
  }
}

function getComparisonPath(permutation: string[]) {
  let path = "";
  for (let i = 0; i < permutation.length; i++) {
    path += permutation[i];
    if (i != permutation.length - 1) {
      path += " vs ";
    }
  }
  return path;
}

function getUrlPath(permutation: string[]) {
  let path = "";
  for (let i = 0; i < permutation.length; i++) {
    path += permutation[i];
    if (i != permutation.length - 1) {
      path += "-vs-";
    }
  }
  return path;
}

export default function DeclarativeMapperPage(
  props: DeclarativeMapperPageProps
) {
  let [frameworksList, setFrameworksList] = React.useState(
    props.pageContext.comparison
  );
  const [showSnackbar, setShowSnackbar] = React.useState(false);
  const displaySnackbar = () => {
    setShowSnackbar(true);
  };

  const handleCloseSnackbar = (event: any) => {
    if (event instanceof TouchEvent || event instanceof PointerEvent) {
      return;
    }
    setShowSnackbar(false);
  };

  const frameworkData: StringKeyArray = {
    concepts: props.pageContext.frameworks.concepts,
    metadata: props.pageContext.frameworks.metadata,
  };

  const components = {
    pre: CodeBlock,
  };

  function navigateToUpdatedScreen(frameworks: string[]) {
    const path =
      "/compare-declarative-frameworks/" +
      (frameworks.length > 1 ? frameworks.join("-vs-") : frameworks[0] || "");
    navigate(path);
  }

  // Create FAQ schema for passing to Layout
  const getFaqSchema = () => {
    if (props.pageContext.comparison.length < 2) return null;

    const faqs = getFaqsForComparison(props.pageContext.comparison);
    if (faqs.length === 0) return null;

    return {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: faqs.map((faq) => ({
        "@type": "Question",
        name: faq.question,
        acceptedAnswer: {
          "@type": "Answer",
          text: faq.answer,
        },
      })),
    };
  };

  return (
    <Layout
      maxWidth="xl"
      pageTitle={
        props.pageContext.comparison.length >= 1
          ? `${getComparisonPath(
              props.pageContext.comparison
            )} - Compare Declarative UI Frameworks`
          : "Compare Declarative UI Frameworks"
      }
      pageDescription={
        props.pageContext.comparison.length >= 1
          ? `${getComparisonPath(
              props.pageContext.comparison
            )} - see how these declarative frameworks compare on various concepts like creating a content, prop drilling, state management, conditional rendering, slot api's etc.`
          : "Compare declarative frameworks like Jetpack Compose, Flutter, SwiftUI, React and Vue.js. See how they compare on various concepts like creating a content, prop drilling, state management, conditional rendering, slot api's etc."
      }
      pageSlug={
        props.pageContext.comparison.length == 0
          ? "/compare-declarative-frameworks"
          : `/compare-declarative-frameworks/${getUrlPath(
              props.pageContext.comparison
            )}`
      }
      pageImageUrl="/compare-frameworks.png"
      seoImageUrl="/compare-frameworks.png"
      structuredData={getFaqSchema()}
    >
      <div className="w-full px-4 pb-16">
        <PageTitle
          header="Compare Declarative Frameworks"
          subheader="Choose up-to 3 frameworks and learn how they compare to each other."
        />
        <Snackbar
          open={showSnackbar}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
        >
          <Alert onClose={handleCloseSnackbar} severity="error">
            You can only compare up-to 3 frameworks at a time.
          </Alert>
        </Snackbar>
        <div className="w-full flex justify-center items-center mb-12">
          {frameworkData.metadata.map((meta) => {
            const isFrameworkSelected = frameworksList.includes(meta.id);
            return (
              <div
                className={`flex-row flex justify-center items-center mr-2 rounded-full py-2 px-4 border-2 hover:border-slate-300 hover:bg-slate-100 hover:cursor-pointer ${
                  isFrameworkSelected ? "!bg-slate-200 !border-[#222]" : ""
                }`}
                onClick={() => {
                  if (frameworksList.includes(meta.id)) {
                    const frameworkListCopy = Object.assign([], frameworksList);
                    const updatedList = removeItemFromArray(
                      frameworkListCopy,
                      meta.id
                    );
                    navigateToUpdatedScreen(updatedList);
                  } else if (frameworksList.length <= 2) {
                    const updatedList = [...frameworksList, meta.id];
                    navigateToUpdatedScreen(updatedList);
                  } else if (frameworksList.length == 3) {
                    displaySnackbar();
                  }
                }}
              >
                <GatsbyImage
                  image={getImage(meta.logo) as IGatsbyImageData}
                  alt="Framework Logo"
                  className="w-6 h-6"
                  objectFit="contain"
                />
                <div className="text-base text-black pl-2 hidden lg:block">
                  {frameworkToDisplayName(meta.id)}
                </div>
                <Cancel
                  className={`w-5 h-5 ml-1 ${
                    isFrameworkSelected ? "block" : "invisible lg:hidden"
                  }`}
                />
              </div>
            );
          })}
        </div>
        <div className={`grid ${gridCols[frameworksList.length]}`}>
          <div
            className={`text-xl font-inter font-normal p-4 hidden lg:block w-full bg-[#4636f7] text-white ${
              keyColSpan[frameworksList.length]
            }`}
          >
            Concept
          </div>
          {frameworksList.length == 0 ? (
            <div className={`p-4 ${itemColSpan[frameworksList.length]}`}></div>
          ) : null}
          {frameworksList.map((framework) => {
            return (
              <div
                className={`text-xl font-inter font-normal text-center p-4 hidden lg:block bg-[#4636f7] text-white ${
                  itemColSpan[frameworksList.length]
                }`}
              >
                <span className="">{frameworkToDisplayName(framework)}</span>
              </div>
            );
          })}

          {props.pageContext.frameworks.concepts.map((concept, index) => {
            return (
              <>
                <div
                  className={`${
                    keyColSpan[frameworksList.length]
                  } px-4 lg:py-8 py-4 w-full ${
                    index % 2 === 0 ? "" : "bg-indigo-50"
                  }`}
                >
                  <div className="text-xl font-inter">{concept.title}</div>
                  <div className="text-lg font-nunitosans mt-4">
                    {concept.description}
                  </div>
                </div>
                {frameworksList.length == 0 ? (
                  <div
                    className={`px-4 lg:py-8 py-4 ${
                      itemColSpan[frameworksList.length]
                    }`}
                  >
                    {index == 0 ? (
                      <div className="flex justify-center items-center flex-col">
                        <div className="font-inter text-xl font-bold">
                          Pick a framework from the list above
                        </div>{" "}
                        <StaticImage
                          src="../images/empty_list.png"
                          className=""
                          alt="Pick a framework from the list above"
                          placeholder="blurred"
                          transformOptions={{ fit: "inside" }}
                          width={250}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : null}
                {frameworksList.map((framework) => {
                  const frameworkMdxNode =
                    props.pageContext.frameworksMdx.filter((item) => {
                      return (
                        concept.id === item.frontmatter.id &&
                        framework === item.frontmatter.framework
                      );
                    })[0];
                  return (
                    <div
                      className={`px-4 lg:py-8 py-4 ${
                        itemColSpan[frameworksList.length]
                      } ${index % 2 === 0 ? "" : "bg-indigo-50"}`}
                    >
                      <div className="font-inter text-base font-bold visible lg:hidden pb-4">
                        {frameworkToDisplayName(framework)}
                      </div>
                      {frameworkMdxNode ? (
                        <article className="prose prose-slate prose-pre:rounded-2xl prose-pre:!my-0">
                          {(() => {
                            const ThemedCodeBlock: FC = (props) => (
                              <CodeBlock
                                {...props}
                                language={frameworkMdxNode.frontmatter.language}
                              />
                            );
                            const components = { pre: ThemedCodeBlock };

                            return (
                              <MDXProvider components={components}>
                                <MDXRenderer
                                  frontmatter={frameworkMdxNode.frontmatter}
                                >
                                  {frameworkMdxNode.body}
                                </MDXRenderer>
                              </MDXProvider>
                            );
                          })()}
                        </article>
                      ) : null}
                    </div>
                  );
                })}
              </>
            );
          })}
        </div>
        {props.pageContext.comparison.length >= 2 && (
          <div className="mt-16 border-t pt-8">
            <h2 className="text-2xl font-bold mb-6 font-inter">
              Frequently Asked Questions About{" "}
              {props.pageContext.comparison
                .map((f) => frameworkToDisplayName(f))
                .join(" vs ")}
            </h2>

            <div className="space-y-6">
              {getFaqsForComparison(props.pageContext.comparison).map(
                (faq, index) => (
                  <div
                    key={index}
                    className="border border-gray-200 rounded-lg p-6 shadow-sm hover:shadow-md transition-shadow duration-200"
                  >
                    <h3 className="text-xl font-semibold mb-3 font-inter">
                      {faq.question}
                    </h3>
                    <div
                      className="prose max-w-none font-nunitosans"
                      dangerouslySetInnerHTML={{ __html: faq.answer }}
                    ></div>
                  </div>
                )
              )}
            </div>
          </div>
        )}
        <div className="mt-16">
          <DonateRow />
        </div>
      </div>
    </Layout>
  );
}

interface Faq {
  question: string;
  answer: string;
}

function getFaqsForComparison(frameworks: string[]): Faq[] {
  if (frameworks.length < 2) return [];

  // Convert framework IDs to display names for questions
  const displayNames = frameworks.map(frameworkToDisplayName);

  // Base questions that work for any framework comparison
  const baseQuestions: Faq[] = [
    {
      question: `Which is better for beginners, ${displayNames.join(" or ")}?`,
      answer: getBeginnerComparisonAnswer(frameworks),
    },
    {
      question: `How does the performance of ${displayNames[0]} compare to ${displayNames[1]} in real-world applications?`,
      answer: getPerformanceComparisonAnswer(frameworks[0], frameworks[1]),
    },
    {
      question: `What are the key architectural differences between ${displayNames.join(
        " and "
      )}?`,
      answer: getKeyDifferencesAnswer(frameworks),
    },
    {
      question: `What are the job market trends for ${displayNames.join(
        " vs "
      )} in ${new Date().getFullYear()}?`,
      answer: getCareerProspectsAnswer(frameworks),
    },
    {
      question: `Can ${displayNames.join(
        " and "
      )} be used together in the same project?`,
      answer: getInteroperabilityAnswer(frameworks),
    },
  ];

  // Framework-specific questions
  const specificQuestions: Faq[] = getFrameworkSpecificQuestions(frameworks);

  // Combine and return all questions
  return [...baseQuestions, ...specificQuestions];
}

function getBeginnerComparisonAnswer(frameworks: string[]): string {
  const beginnerFriendliness: {
    [key: string]: {
      rating: number;
      explanation: string;
      learningPath: string[];
      prerequisites: string[];
      timeToProductivity: string;
    };
  } = {
    JetpackCompose: {
      rating: 3,
      explanation:
        "Jetpack Compose has a moderate learning curve that requires understanding of Kotlin and Android fundamentals. Its functional programming approach and declarative syntax can be challenging for developers coming from imperative XML layouts, but the excellent tooling and preview system make the learning process smoother.",
      learningPath: [
        "Learn Kotlin fundamentals (especially lambdas and higher-order functions)",
        "Understand Android Activity/Fragment lifecycle",
        "Master Compose basics (composables, state, side effects)",
        "Learn Material Design components and theming",
        "Practice state management and composition patterns",
      ],
      prerequisites: ["Kotlin", "Android basics", "Gradle build system"],
      timeToProductivity:
        "2-3 months for Android developers, 4-6 months for beginners",
    },
    SwiftUI: {
      rating: 4,
      explanation:
        "SwiftUI offers an intuitive approach for iOS development with excellent documentation and powerful preview features. While it requires understanding Swift and iOS concepts, its declarative syntax and strong type system help catch errors early and make the development process more predictable.",
      learningPath: [
        "Master Swift basics (especially protocols and property wrappers)",
        "Understand iOS app architecture",
        "Learn SwiftUI view hierarchy and data flow",
        "Practice with property wrappers and state management",
        "Explore SwiftUI's animation system",
      ],
      prerequisites: ["Swift", "iOS development concepts", "Xcode"],
      timeToProductivity:
        "2-3 months for iOS developers, 4-5 months for beginners",
    },
    Flutter: {
      rating: 3,
      explanation:
        "Flutter requires learning Dart, which may be unfamiliar to many developers. However, its comprehensive documentation, hot reload feature, and widget-based architecture make the learning process systematic. The consistent behavior across platforms reduces platform-specific complexity.",
      learningPath: [
        "Learn Dart programming language",
        "Understand Flutter widget system",
        "Master state management approaches",
        "Learn platform integration techniques",
        "Practice responsive design patterns",
      ],
      prerequisites: [
        "Dart",
        "Basic programming concepts",
        "Mobile UI principles",
      ],
      timeToProductivity:
        "3-4 months for mobile developers, 4-6 months for beginners",
    },
    React: {
      rating: 4,
      explanation:
        "React's component-based architecture and extensive ecosystem make it accessible for beginners. While concepts like hooks and virtual DOM require time to master, the large community and abundance of learning resources help overcome challenges. TypeScript adoption adds type safety but increases the initial learning curve.",
      learningPath: [
        "Learn modern JavaScript/TypeScript",
        "Understand React components and JSX",
        "Master hooks and state management",
        "Learn component lifecycle and effects",
        "Practice React patterns and best practices",
      ],
      prerequisites: ["JavaScript/TypeScript", "HTML/CSS", "npm/yarn"],
      timeToProductivity:
        "2-3 months for web developers, 3-4 months for beginners",
    },
    VueJS: {
      rating: 5,
      explanation:
        "Vue.js is highly beginner-friendly with its progressive learning curve and clear documentation. Its template syntax feels natural to HTML developers, while the Composition API offers a powerful way to organize complex logic. The framework provides official solutions for common needs, reducing decision fatigue.",
      learningPath: [
        "Learn Vue template syntax and directives",
        "Understand component system",
        "Master Composition API",
        "Learn Vue Router and state management",
        "Practice Vue best practices and patterns",
      ],
      prerequisites: ["HTML/CSS", "JavaScript basics", "npm/yarn"],
      timeToProductivity:
        "1-2 months for web developers, 2-3 months for beginners",
    },
  };

  // Sort frameworks by beginner-friendliness
  const sortedFrameworks = [...frameworks].sort(
    (a, b) =>
      (beginnerFriendliness[b]?.rating || 0) -
      (beginnerFriendliness[a]?.rating || 0)
  );

  let answer = `<p>Let's analyze the learning curve and requirements for each framework in ${new Date().getFullYear()}:</p>`;

  sortedFrameworks.forEach((framework) => {
    if (beginnerFriendliness[framework]) {
      const data = beginnerFriendliness[framework];
      answer += `
        <div class="mb-6">
          <h4 class="text-lg font-semibold mb-2">${frameworkToDisplayName(
            framework
          )} (${data.rating}/5)</h4>
          <p class="mb-2">${data.explanation}</p>
          
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mt-3">
            <div class="bg-gray-50 p-4 rounded-lg">
              <h5 class="font-medium mb-2">Learning Path:</h5>
              <ol class="list-decimal pl-5">
                ${data.learningPath.map((step) => `<li>${step}</li>`).join("")}
              </ol>
            </div>
            
            <div class="bg-gray-50 p-4 rounded-lg">
              <h5 class="font-medium mb-2">Key Prerequisites:</h5>
              <ul class="list-disc pl-5">
                ${data.prerequisites
                  .map((prereq) => `<li>${prereq}</li>`)
                  .join("")}
              </ul>
              <p class="mt-2"><strong>Time to Productivity:</strong> ${
                data.timeToProductivity
              }</p>
            </div>
          </div>
        </div>`;
    }
  });

  answer += `
    <div class="bg-blue-50 p-4 rounded-lg mt-4">
      <h4 class="font-semibold mb-2">Recommendation</h4>
      <p>Based on the analysis, ${frameworkToDisplayName(
        sortedFrameworks[0]
      )} offers the most approachable learning curve. However, your choice should depend on:</p>
      <ul class="list-disc pl-5 mt-2">
        <li>Your existing programming background (${frameworks
          .map((f) => beginnerFriendliness[f].prerequisites[0])
          .join(", ")})</li>
        <li>Target platform requirements (${frameworks
          .map((f) =>
            f === "JetpackCompose"
              ? "Android"
              : f === "SwiftUI"
              ? "iOS"
              : "Cross-platform"
          )
          .join(", ")})</li>
        <li>Available learning time (${
          beginnerFriendliness[sortedFrameworks[0]].timeToProductivity
        } for ${frameworkToDisplayName(sortedFrameworks[0])})</li>
        <li>Long-term career goals in mobile/web development</li>
      </ul>
    </div>`;

  return answer;
}

function getPerformanceComparisonAnswer(
  framework1: string,
  framework2: string
): string {
  const performanceData: {
    [key: string]: {
      strengths: Array<{ point: string; explanation: string }>;
      weaknesses: Array<{ point: string; explanation: string }>;
      benchmarks: Array<{ metric: string; value: string }>;
      optimizationTips: string[];
    };
  } = {
    JetpackCompose: {
      strengths: [
        {
          point: "Efficient recomposition system",
          explanation:
            "Uses smart recomposition that only updates components when their inputs change, reducing unnecessary UI updates.",
        },
        {
          point: "Optimized rendering pipeline",
          explanation:
            "Compose leverages Android's rendering pipeline to optimize performance for animations and transitions.",
        },
        {
          point: "Memory efficiency",
          explanation:
            "Compose's compiler plugin optimizes memory allocation by reusing existing objects and reducing unnecessary allocations during UI updates.",
        },
      ],
      weaknesses: [
        {
          point: "Initial release overhead",
          explanation:
            "First-time compilation and initial app startup time can be slower compared to XML layouts. You can address this by leveraging Baseline Profile.",
        },
        {
          point: "Complex state management impact",
          explanation:
            "Improper state management can trigger unnecessary recompositions, affecting performance.",
        },
      ],
      benchmarks: [
        { metric: "Initial render time", value: "~16ms for simple views" },
        { metric: "Memory footprint", value: "20-30% more than XML initially" },
        {
          metric: "Animation frame rate",
          value: "60fps with proper implementation",
        },
      ],
      optimizationTips: [
        "Use remember() and derivedStateOf() to minimize recompositions",
        "Implement proper key() usage in lists for efficient updates",
        "Leverage Compose's built-in lazy loading components",
        "Profile with Android Studio's Layout Inspector and Performance tools",
      ],
    },
    SwiftUI: {
      strengths: [
        {
          point: "Efficient diffing algorithm",
          explanation:
            "Uses a sophisticated diffing algorithm to minimize view updates and maintain smooth performance.",
        },
        {
          point: "Native platform optimization",
          explanation:
            "Direct integration with Apple's rendering engine provides excellent performance on iOS devices.",
        },
        {
          point: "Automatic memory management",
          explanation:
            "Swift's ARC (Automatic Reference Counting) ensures efficient memory usage.",
        },
      ],
      weaknesses: [
        {
          point: "List performance issues",
          explanation:
            "Complex lists with dynamic content can experience performance degradation.",
        },
        {
          point: "State propagation overhead",
          explanation:
            "Deep view hierarchies with frequent state updates can impact performance.",
        },
      ],
      benchmarks: [
        { metric: "Initial render time", value: "~12ms for simple views" },
        { metric: "Memory footprint", value: "Similar to UIKit" },
        {
          metric: "Animation frame rate",
          value: "60fps with proper implementation",
        },
      ],
      optimizationTips: [
        "Use @StateObject for expensive objects that need to persist",
        "Implement lazy loading with LazyVStack and LazyHStack",
        "Leverage SwiftUI's built-in performance tools",
        "Profile with Instruments to identify bottlenecks",
      ],
    },
    Flutter: {
      strengths: [
        {
          point: "Custom rendering engine",
          explanation:
            "Skia rendering engine provides consistent performance across platforms without relying on native components.",
        },
        {
          point: "Widget tree optimization",
          explanation:
            "Efficient widget rebuilding system that minimizes the impact of UI updates.",
        },
        {
          point: "JIT/AOT compilation",
          explanation:
            "Supports both Just-in-Time compilation for development and Ahead-of-Time compilation for release builds.",
        },
      ],
      weaknesses: [
        {
          point: "Initial app size",
          explanation:
            "Larger app size due to bundled runtime and engine components.",
        },
        {
          point: "Complex screen jank",
          explanation:
            "Can experience frame drops on screens with complex animations or heavy computation.",
        },
      ],
      benchmarks: [
        { metric: "Initial render time", value: "~8ms for simple views" },
        { metric: "Memory footprint", value: "20-40MB baseline" },
        {
          metric: "Animation frame rate",
          value: "60fps with proper implementation",
        },
      ],
      optimizationTips: [
        "Use const constructors for static widgets",
        "Implement proper keys in lists for efficient updates",
        "Leverage Flutter's built-in performance overlay",
        "Profile with DevTools to identify performance bottlenecks",
      ],
    },
    React: {
      strengths: [
        {
          point: "Virtual DOM optimization",
          explanation:
            "Efficient diffing algorithm minimizes actual DOM updates, improving performance.",
        },
        {
          point: "Code splitting",
          explanation:
            "Built-in support for code splitting and lazy loading of components.",
        },
        {
          point: "Concurrent rendering",
          explanation:
            "React 18's concurrent features allow for prioritized rendering and better user experience.",
        },
      ],
      weaknesses: [
        {
          point: "DOM operations overhead",
          explanation:
            "Multiple DOM operations can still impact performance in complex applications.",
        },
        {
          point: "Bundle size concerns",
          explanation:
            "Large dependency trees can lead to significant bundle sizes.",
        },
      ],
      benchmarks: [
        { metric: "Initial render time", value: "~50ms for simple components" },
        { metric: "Memory usage", value: "Varies by application complexity" },
        { metric: "Time to Interactive", value: "~1.5s for typical apps" },
      ],
      optimizationTips: [
        "Implement React.memo() for expensive computations",
        "Use useMemo and useCallback hooks appropriately",
        "Leverage Code Splitting with React.lazy()",
        "Profile with React DevTools and Lighthouse",
      ],
    },
    VueJS: {
      strengths: [
        {
          point: "Reactive system",
          explanation:
            "Fine-grained reactivity system that updates only affected components.",
        },
        {
          point: "Virtual DOM efficiency",
          explanation:
            "Optimized virtual DOM implementation with static tree hoisting.",
        },
        {
          point: "Template compilation",
          explanation:
            "Templates are compiled into highly optimized render functions.",
        },
      ],
      weaknesses: [
        {
          point: "Complex reactivity overhead",
          explanation:
            "Deep reactive objects can have performance implications.",
        },
        {
          point: "Mobile optimization",
          explanation:
            "May require additional optimization for mobile web performance.",
        },
      ],
      benchmarks: [
        { metric: "Initial render time", value: "~40ms for simple components" },
        { metric: "Memory usage", value: "~10-20% less than React" },
        { metric: "Time to Interactive", value: "~1.2s for typical apps" },
      ],
      optimizationTips: [
        "Use v-show for frequently toggled content",
        "Implement proper key usage in v-for directives",
        "Leverage Vue's keep-alive component",
        "Profile with Vue DevTools and Chrome Performance",
      ],
    },
  };

  const f1 = frameworkToDisplayName(framework1);
  const f2 = frameworkToDisplayName(framework2);

  let answer = `<p>Let's analyze the real-world performance characteristics of ${f1} and ${f2} based on benchmarks and practical experience:</p>`;

  // Performance Profiles
  for (const framework of [framework1, framework2]) {
    const data = performanceData[framework];
    const name = frameworkToDisplayName(framework);

    answer += `
      <div class="mb-6">
        <h4 class="text-lg font-semibold mt-4 mb-2">${name} Performance Profile</h4>
        
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div class="bg-gray-50 p-4 rounded-lg">
            <h5 class="font-medium mb-2">Strengths</h5>
            <ul class="space-y-2">
              ${data.strengths
                .map(
                  (s) => `
                <li>
                  <span class="text-green-600">✓</span> <strong>${s.point}</strong>
                  <p class="text-sm text-gray-600 mt-1">${s.explanation}</p>
                </li>
              `
                )
                .join("")}
            </ul>
          </div>

          <div class="bg-gray-50 p-4 rounded-lg">
            <h5 class="font-medium mb-2">Areas for Optimization</h5>
            <ul class="space-y-2">
              ${data.weaknesses
                .map(
                  (w) => `
                <li>
                  <span class="text-red-600">!</span> <strong>${w.point}</strong>
                  <p class="text-sm text-gray-600 mt-1">${w.explanation}</p>
                </li>
              `
                )
                .join("")}
            </ul>
          </div>
        </div>
      </div>`;
  }

  // Framework-specific comparison
  if (
    (framework1 === "JetpackCompose" || framework1 === "SwiftUI") &&
    (framework2 === "React" || framework2 === "VueJS")
  ) {
    answer += `
      <div class="bg-blue-50 p-4 rounded-lg mt-4">
        <h4 class="font-semibold mb-2">Native vs Web Performance</h4>
        <p>${f1}, being a native framework, generally provides better performance for:</p>
        <ul class="list-disc pl-5 mt-2">
          <li>Complex animations and transitions</li>
          <li>Heavy computational tasks</li>
          <li>Memory-intensive operations</li>
          <li>Access to platform-specific optimizations</li>
        </ul>
        <p class="mt-2">However, ${f2} can still deliver excellent performance for most business applications, especially when following optimization best practices.</p>
      </div>`;
  } else if (
    framework1 === "Flutter" &&
    (framework2 === "JetpackCompose" || framework2 === "SwiftUI")
  ) {
    answer += `
      <div class="bg-blue-50 p-4 rounded-lg mt-4">
        <h4 class="font-semibold mb-2">Cross-platform vs Native Performance</h4>
        <p>While ${f2} might have a slight edge in platform-specific optimizations, Flutter's custom rendering engine often delivers comparable performance with additional benefits:</p>
        <ul class="list-disc pl-5 mt-2">
          <li>Consistent performance across platforms</li>
          <li>Predictable animation timing</li>
          <li>Platform-agnostic optimization techniques</li>
        </ul>
      </div>`;
  }

  // Optimization Tips
  answer += `
    <div class="mt-6">
      <h4 class="text-lg font-semibold mb-3">Performance Optimization Tips</h4>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        ${[framework1, framework2]
          .map(
            (framework) => `
          <div class="bg-gray-50 p-4 rounded-lg">
            <h5 class="font-medium mb-2">${frameworkToDisplayName(
              framework
            )}</h5>
            <ul class="list-disc pl-5">
              ${performanceData[framework].optimizationTips
                .map(
                  (tip) => `
                <li>${tip}</li>
              `
                )
                .join("")}
            </ul>
          </div>
        `
          )
          .join("")}
      </div>
    </div>`;

  return answer;
}

function getKeyDifferencesAnswer(frameworks: string[]): string {
  // Key characteristics of each framework
  const characteristics: {
    [key: string]: {
      paradigm: string;
      platform: string;
      language: string;
      components: string;
      state: string;
      ecosystem: string;
    };
  } = {
    JetpackCompose: {
      paradigm: "Declarative UI toolkit with a functional programming approach",
      platform: "Android (with experimental desktop support)",
      language: "Kotlin",
      components: "Composable functions",
      state: "State hoisting with remember and mutableStateOf",
      ecosystem: "Integrated with Android ecosystem and Kotlin coroutines",
    },
    SwiftUI: {
      paradigm: "Declarative UI framework with a protocol-oriented approach",
      platform: "Apple platforms (iOS, macOS, watchOS, tvOS)",
      language: "Swift",
      components: "View protocol conforming structs",
      state: "Property wrappers (@State, @Binding, @ObservedObject)",
      ecosystem: "Tightly integrated with Apple's development ecosystem",
    },
    Flutter: {
      paradigm: "Declarative UI toolkit with a widget-based approach",
      platform: "Cross-platform (iOS, Android, web, desktop)",
      language: "Dart",
      components: "Widget classes (stateless and stateful)",
      state: "StatefulWidget with setState, or state management packages",
      ecosystem: "Google-backed with a growing ecosystem of packages",
    },
    React: {
      paradigm: "Declarative UI library with a component-based approach",
      platform: "Web (with React Native for mobile)",
      language: "JavaScript/TypeScript",
      components: "Function components with hooks or class components",
      state: "useState, useReducer, and third-party solutions like Redux",
      ecosystem: "Vast ecosystem with many libraries and tools",
    },
    VueJS: {
      paradigm:
        "Progressive JavaScript framework with a template-based approach",
      platform: "Web primarily",
      language: "JavaScript/TypeScript",
      components:
        "Single-file components with template, script, and style sections",
      state: "Reactive data with Composition API or Options API",
      ecosystem:
        "Growing ecosystem with official libraries for routing and state",
    },
  };

  let answer = `<p>Here are the key differences between ${frameworks
    .map((f) => frameworkToDisplayName(f))
    .join(" and ")}:</p>`;

  answer += `<table class="min-w-full border border-gray-300 mt-4">
    <thead>
      <tr class="bg-gray-100">
        <th class="py-2 px-4 border">Feature</th>
        ${frameworks
          .map(
            (f) =>
              `<th class="py-2 px-4 border">${frameworkToDisplayName(f)}</th>`
          )
          .join("")}
      </tr>
    </thead>
    <tbody>`;

  // Add rows for each characteristic
  const categories = [
    { key: "paradigm", name: "Paradigm" },
    { key: "platform", name: "Target Platform" },
    { key: "language", name: "Language" },
    { key: "components", name: "Component Model" },
    { key: "state", name: "State Management" },
    { key: "ecosystem", name: "Ecosystem" },
  ];

  categories.forEach((category) => {
    answer += `<tr class="hover:bg-gray-50">
      <td class="py-2 px-4 border font-medium">${category.name}</td>
      ${frameworks
        .map(
          (f) =>
            `<td class="py-2 px-4 border">${
              characteristics[f]?.[
                category.key as keyof (typeof characteristics)[typeof f]
              ] || "-"
            }</td>`
        )
        .join("")}
    </tr>`;
  });

  answer += `</tbody></table>`;

  // Add summary paragraph
  answer += `<p class="mt-4">The choice between these frameworks often depends on your target platform, existing expertise, and specific project requirements. ${frameworks
    .map((f) => frameworkToDisplayName(f))
    .join(" and ")} each have their strengths in different contexts.</p>`;

  return answer;
}

function getCareerProspectsAnswer(frameworks: string[]): string {
  const jobMarket: {
    [key: string]: {
      demand: string;
      growth: string;
      companies: string[];
    };
  } = {
    JetpackCompose: {
      demand:
        "Growing rapidly as more Android apps transition from XML layouts",
      growth:
        "Strong upward trend as Google pushes it as the future of Android UI",
      companies: ["Google", "Twitter", "Square", "Airbnb"],
    },
    SwiftUI: {
      demand: "Increasing as iOS apps adopt the newer framework",
      growth: "Steady growth as Apple continues to enhance capabilities",
      companies: ["Apple", "Uber", "Lyft", "Airbnb"],
    },
    Flutter: {
      demand: "High demand for cross-platform development skills",
      growth: "One of the fastest-growing mobile frameworks",
      companies: ["Google", "Alibaba", "BMW", "eBay"],
    },
    React: {
      demand: "Very high demand across web, mobile (React Native), and desktop",
      growth: "Mature but still growing with continuous innovation",
      companies: ["Meta", "Netflix", "Airbnb", "Dropbox"],
    },
    VueJS: {
      demand: "Solid demand, particularly in certain markets like Asia",
      growth: "Steady growth with strong community support",
      companies: ["Alibaba", "GitLab", "Grammarly", "Nintendo"],
    },
  };

  const currentYear = new Date().getFullYear();

  let answer = `<p>If you're considering a career move in ${currentYear}, here's how these frameworks compare in terms of job prospects:</p>`;

  frameworks.forEach((framework) => {
    const name = frameworkToDisplayName(framework);
    const data = jobMarket[framework];

    answer += `<div class="mb-4">
      <h4 class="text-lg font-semibold mt-3 mb-2">${name}</h4>
      <ul class="list-disc pl-5">
        <li><strong>Current Demand:</strong> ${data.demand}</li>
        <li><strong>Growth Trajectory:</strong> ${data.growth}</li>
        <li><strong>Notable Companies:</strong> ${data.companies.join(
          ", "
        )}</li>
      </ul>
    </div>`;
  });

  // Add strategic advice based on the specific frameworks being compared
  if (frameworks.includes("React") && frameworks.includes("VueJS")) {
    answer += `<p>Between React and Vue.js, React currently has a larger job market, but Vue.js positions often have less competition. Both are excellent choices for web development careers.</p>`;
  }

  if (frameworks.includes("JetpackCompose") && frameworks.includes("SwiftUI")) {
    answer += `<p>For mobile development, specializing in both Jetpack Compose and SwiftUI makes you versatile across the two major mobile platforms, though each individually pairs well with their platform-specific knowledge.</p>`;
  }

  if (
    frameworks.includes("Flutter") &&
    (frameworks.includes("JetpackCompose") || frameworks.includes("SwiftUI"))
  ) {
    answer += `<p>Flutter offers the advantage of cross-platform skills, while native frameworks like ${
      frameworks.includes("JetpackCompose") ? "Jetpack Compose" : "SwiftUI"
    } may provide deeper platform integration. Many companies value developers who can work in both worlds.</p>`;
  }
  return answer;
}

function getInteroperabilityAnswer(frameworks: string[]): string {
  // Information about how frameworks can be used together
  const interoperability: { [key: string]: { [key: string]: string } } = {
    JetpackCompose: {
      SwiftUI:
        "Jetpack Compose and SwiftUI cannot be directly integrated as they target different platforms (Android vs. iOS). However, you can share business logic between them using Kotlin Multiplatform.",
      Flutter:
        "Jetpack Compose can be integrated with Flutter through platform channels, allowing you to use native Android functionality within a Flutter app.",
      React:
        "React (via React Native) can use Jetpack Compose through native modules, though this is complex. Alternatively, Compose for Web is an experimental project that brings Compose concepts to web development.",
      VueJS:
        "There's no direct integration between Vue.js and Jetpack Compose as they target different platforms. You would typically build separate apps for web and Android.",
    },
    SwiftUI: {
      JetpackCompose:
        "SwiftUI and Jetpack Compose cannot be directly integrated as they target different platforms (iOS vs. Android). However, you can share business logic between them using Kotlin Multiplatform.",
      Flutter:
        "SwiftUI can be integrated with Flutter through platform channels, allowing you to use native iOS functionality within a Flutter app.",
      React:
        "React (via React Native) can use SwiftUI through native modules, though this is complex and not typically recommended.",
      VueJS:
        "There's no direct integration between Vue.js and SwiftUI as they target different platforms. You would typically build separate apps for web and iOS.",
    },
    Flutter: {
      JetpackCompose:
        "Flutter can use Jetpack Compose functionality through platform channels, allowing Flutter apps to access native Android capabilities.",
      SwiftUI:
        "Flutter can use SwiftUI functionality through platform channels, allowing Flutter apps to access native iOS capabilities.",
      React:
        "Flutter and React/React Native are separate frameworks. While both can be used in the same project (e.g., Flutter for one feature, React Native for another), this is uncommon and complex.",
      VueJS:
        "Flutter is for mobile/desktop apps while Vue.js is for web. They can be used together as part of a larger product ecosystem but not within the same application.",
    },
    React: {
      JetpackCompose:
        "React Native can integrate with Jetpack Compose through native modules, allowing you to use Compose UI components within a React Native Android app.",
      SwiftUI:
        "React Native can integrate with SwiftUI through native modules, allowing you to use SwiftUI components within a React Native iOS app.",
      Flutter:
        "React and Flutter are separate frameworks. While both can be used in the same project, this is uncommon and complex.",
      VueJS:
        "React and Vue.js can coexist in the same web application, though this is uncommon. Micro-frontends architecture might use both frameworks for different parts of a large application.",
    },
    VueJS: {
      JetpackCompose:
        "There's no direct integration between Vue.js and Jetpack Compose as they target different platforms. You would typically build separate apps for web and Android.",
      SwiftUI:
        "There's no direct integration between Vue.js and SwiftUI as they target different platforms. You would typically build separate apps for web and iOS.",
      Flutter:
        "Vue.js is for web while Flutter is for mobile/desktop apps. They can be used together as part of a larger product ecosystem but not within the same application.",
      React:
        "Vue.js and React can coexist in the same web application, though this is uncommon. Micro-frontends architecture might use both frameworks for different parts of a large application.",
    },
  };

  let answer = `<p>Understanding how ${frameworks
    .map((f) => frameworkToDisplayName(f))
    .join(" and ")} can work together:</p><div class="space-y-4 mt-3">`;

  // Create comparisons for each pair of frameworks
  for (let i = 0; i < frameworks.length; i++) {
    for (let j = i + 1; j < frameworks.length; j++) {
      const f1 = frameworks[i];
      const f2 = frameworks[j];
      const info =
        interoperability[f1]?.[f2] ||
        "No direct integration path is available between these frameworks.";

      answer += `<div class="p-4 border rounded-lg bg-gray-50">
        <h4 class="font-semibold mb-2">${frameworkToDisplayName(
          f1
        )} + ${frameworkToDisplayName(f2)}</h4>
        <p>${info}</p>
      </div>`;
    }
  }

  answer += `</div>`;

  // Add general advice
  if (frameworks.length > 2) {
    answer += `<p class="mt-3"><strong>Using multiple frameworks:</strong> While it's technically possible to use ${frameworks
      .map((f) => frameworkToDisplayName(f))
      .join(
        ", "
      )} in a single project ecosystem, this adds complexity. It's generally better to choose the right tool for each platform and maintain consistency within that platform.</p>`;
  }

  const webFrameworks = frameworks.filter(
    (f) => f === "React" || f === "VueJS"
  );
  const mobileFrameworks = frameworks.filter(
    (f) => f === "JetpackCompose" || f === "SwiftUI" || f === "Flutter"
  );

  if (webFrameworks.length > 0 && mobileFrameworks.length > 0) {
    answer += `<p class="mt-3"><strong>Web + Mobile Strategy:</strong> A common approach is to use ${webFrameworks
      .map((f) => frameworkToDisplayName(f))
      .join(" or ")} for your web application, while using ${mobileFrameworks
      .map((f) => frameworkToDisplayName(f))
      .join(
        " or "
      )} for mobile apps. You can share business logic and API calls between them, but the UI layer would be implemented separately for each platform.</p>`;
  }

  return answer;
}

function getFrameworkSpecificQuestions(frameworks: string[]): Faq[] {
  const specificQuestions: Faq[] = [];

  // Questions for specific framework combinations
  if (frameworks.includes("JetpackCompose") && frameworks.includes("SwiftUI")) {
    specificQuestions.push({
      question: "Can I share code between Jetpack Compose and SwiftUI?",
      answer: `<p>Yes, you can share code between Jetpack Compose and SwiftUI applications using Kotlin Multiplatform (KMP). Here's how:</p>
      <ul>
        <li><strong>Shared Business Logic:</strong> Use KMP to write your data models, repositories, and business logic once in Kotlin and use it on both platforms.</li>
        <li><strong>Platform-Specific UI:</strong> Write your UI separately with Jetpack Compose for Android and SwiftUI for iOS, but have them connect to the shared KMP code.</li>
        <li><strong>Networking & Storage:</strong> Libraries like Ktor (networking) and SQLDelight (database) work well with KMP to share these layers across platforms.</li>
      </ul>`,
    });
  }

  if (frameworks.includes("React") && frameworks.includes("VueJS")) {
    specificQuestions.push({
      question:
        "What are the key philosophical differences between React and Vue.js?",
      answer: `<p>React and Vue.js have different design philosophies that affect how you build applications:</p>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mt-3">
        <div class="p-4 bg-blue-50 rounded-lg">
          <h4 class="font-bold mb-2">React Philosophy</h4>
          <ul class="list-disc pl-5">
            <li><strong>JavaScript-centric:</strong> Uses JSX to mix HTML with JavaScript</li>
            <li><strong>Explicit:</strong> State updates require explicit calls</li>
            <li><strong>Functional:</strong> Embraces functional programming concepts</li>
            <li><strong>Community-driven:</strong> Most solutions come from community packages</li>
            <li><strong>Freedom:</strong> Minimal restrictions on how to structure your app</li>
          </ul>
        </div>
        <div class="p-4 bg-green-50 rounded-lg">
          <h4 class="font-bold mb-2">Vue.js Philosophy</h4>
          <ul class="list-disc pl-5">
            <li><strong>Template-oriented:</strong> HTML templates with enhancements</li>
            <li><strong>Reactive:</strong> Automatic tracking of dependencies</li>
            <li><strong>Progressive:</strong> Can be adopted incrementally</li>
            <li><strong>Opinionated core:</strong> Official solutions for routing, state management</li>
            <li><strong>Structure:</strong> More guidance on application architecture</li>
          </ul>
        </div>
      </div>
      <p class="mt-3">Neither approach is inherently better - React's flexibility works well for complex applications with experienced teams, while Vue's structure and approachability can accelerate development for smaller teams or those new to frontend development.</p>`,
    });
  }

  if (
    frameworks.includes("Flutter") &&
    (frameworks.includes("JetpackCompose") || frameworks.includes("SwiftUI"))
  ) {
    specificQuestions.push({
      question: `Is Flutter better than ${
        frameworks.includes("JetpackCompose") ? "Jetpack Compose" : "SwiftUI"
      } for app development?`,
      answer: `<p>The choice between Flutter and ${
        frameworks.includes("JetpackCompose") ? "Jetpack Compose" : "SwiftUI"
      } depends on your project requirements:</p>
      <table class="min-w-full border border-gray-300 mt-4">
        <thead>
          <tr class="bg-gray-100">
            <th class="py-2 px-4 border">Aspect</th>
            <th class="py-2 px-4 border">Flutter</th>
            <th class="py-2 px-4 border">${
              frameworks.includes("JetpackCompose")
                ? "Jetpack Compose"
                : "SwiftUI"
            }</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="py-2 px-4 border font-medium">Platform Support</td>
            <td class="py-2 px-4 border">iOS, Android, Web, Windows, macOS, Linux</td>
            <td class="py-2 px-4 border">${
              frameworks.includes("JetpackCompose")
                ? "Android (with experimental desktop support)"
                : "iOS, macOS, watchOS, tvOS"
            }</td>
          </tr>
          <tr>
            <td class="py-2 px-4 border font-medium">Native Integration</td>
            <td class="py-2 px-4 border">Good via platform channels, but not direct</td>
            <td class="py-2 px-4 border">Excellent native platform integration</td>
          </tr>
          <tr>
            <td class="py-2 px-4 border font-medium">Performance</td>
            <td class="py-2 px-4 border">Very good with custom rendering engine</td>
            <td class="py-2 px-4 border">Excellent on target platform</td>
          </tr>
          <tr>
            <td class="py-2 px-4 border font-medium">Development Speed</td>
            <td class="py-2 px-4 border">Fast with hot reload and single codebase</td>
            <td class="py-2 px-4 border">Fast for its target platform</td>
          </tr>
          <tr>
            <td class="py-2 px-4 border font-medium">UI Consistency</td>
            <td class="py-2 px-4 border">Same UI across all platforms</td>
            <td class="py-2 px-4 border">Platform-specific UI with native feel</td>
          </tr>
        </tbody>
      </table>
      <p class="mt-3"><strong>Choose Flutter if:</strong></p>
      <ul class="list-disc pl-5">
        <li>You need to support multiple platforms with one codebase</li>
        <li>UI consistency across platforms is more important than native platform feel</li>
        <li>You want to reduce development and maintenance costs</li>
        <li>Your team can focus on learning one technology stack (Dart)</li>
      </ul>
      <p class="mt-3"><strong>Choose ${
        frameworks.includes("JetpackCompose") ? "Jetpack Compose" : "SwiftUI"
      } if:</strong></p>
      <ul class="list-disc pl-5">
        <li>You're only targeting ${
          frameworks.includes("JetpackCompose") ? "Android" : "Apple"
        } platforms</li>
        <li>Deep platform integration is critical for your app</li>
        <li>You want the most native feel and performance</li>
        <li>Your team already has expertise in ${
          frameworks.includes("JetpackCompose") ? "Kotlin" : "Swift"
        }</li>
      </ul>
      <p class="mt-3">Many companies use both approaches: Flutter for cross-platform features and ${
        frameworks.includes("JetpackCompose") ? "Jetpack Compose" : "SwiftUI"
      } for platform-specific features that require deeper integration.</p>`,
    });
  }

  // Add framework-specific questions for any combination
  if (frameworks.includes("Flutter")) {
    specificQuestions.push({
      question: "Why does Flutter use Dart instead of a more common language?",
      answer: `<p>Flutter's choice of Dart as its programming language offers several technical advantages:</p>
      <ul class="list-disc pl-5">
        <li><strong>Just-in-Time (JIT) compilation</strong> during development enables hot reload, allowing for quick iteration</li>
        <li><strong>Ahead-of-Time (AOT) compilation</strong> for releases creates high-performance native code</li>
        <li><strong>Non-blocking asynchronous programming</strong> through async/await and Future objects</li>
        <li><strong>Sound null safety</strong> helps eliminate null reference errors</li>
        <li><strong>Fast garbage collection</strong> optimized for UI construction patterns</li>
        <li><strong>Object-oriented</strong> with mixins for reusable code</li>
      </ul>
      <p class="mt-3">While languages like JavaScript or Kotlin might have larger communities, Dart was specifically optimized for Flutter's needs in building reactive UIs and achieving native performance. Google has invested heavily in making Dart an excellent language for UI development.</p>
      <p class="mt-3">Despite being less common, Dart is easy to learn for developers familiar with Java, JavaScript, or C#, with most developers becoming productive within a few weeks.</p>`,
    });
  }

  if (frameworks.includes("JetpackCompose")) {
    specificQuestions.push({
      question:
        "How does Jetpack Compose compare to traditional Android XML layouts?",
      answer: `<p>Jetpack Compose represents a significant shift from traditional Android XML layouts:</p>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mt-3">
        <div class="p-4 border rounded-lg">
          <h4 class="font-bold mb-2">Traditional XML Layouts</h4>
          <ul class="list-disc pl-5">
            <li>Declarative XML with imperative Java/Kotlin manipulation</li>
            <li>View hierarchy with expensive findViewById() calls</li>
            <li>Complex layouts like ConstraintLayout for performance</li>
            <li>Separate files for layouts, styles, and logic</li>
            <li>Many boilerplate adapters and view holders</li>
            <li>Slow layout inflation process</li>
          </ul>
        </div>
        <div class="p-4 border rounded-lg bg-indigo-50">
          <h4 class="font-bold mb-2">Jetpack Compose</h4>
          <ul class="list-disc pl-5">
            <li>Fully declarative Kotlin code for UI</li>
            <li>No view hierarchy or findViewById()</li>
            <li>Layout composables handle optimization automatically</li>
            <li>UI, styling, and logic in one place</li>
            <li>Simple list creation with LazyColumn/LazyRow</li>
            <li>No layout inflation, faster rendering</li>
          </ul>
        </div>
      </div>
      <p class="mt-3">Compose brings significant advantages in:</p>
      <ul>
        <li><strong>Code reduction:</strong> Much less boilerplate code compared to XML</li>
        <li><strong>State management:</strong> Built-in state handling with react-like patterns</li>
        <li><strong>Preview:</strong> @Preview annotation for seeing UI changes without deploying</li>
        <li><strong>Animation:</strong> Simplified animations with type-safe builders</li>
        <li><strong>Testing:</strong> Better testability without complex UI testing setups</li>
      </ul>
      <p class="mt-3">Migration can be gradual - Compose can be adopted incrementally within existing XML-based apps through the ComposeView component.</p>`,
    });
  }

  return specificQuestions;
}
